// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../../../../../../../styleguide/components/Heading/H2.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationShowContentFeatures from "../features/LocationShowContentFeatures.res.js";
import * as LocationShowContentDetailsCss from "./LocationShowContentDetailsCss.res.js";

function LocationShowContentDetails(props) {
  var $$location = props.location;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H2.make, {
                              id: "contentDetailsTitle",
                              className: LocationShowContentDetailsCss.contentDetailsTitle,
                              children: "About " + $$location.name
                            }),
                        JsxRuntime.jsx("div", {
                              className: LocationShowContentDetailsCss.contentDescription,
                              id: "contentDescription",
                              dangerouslySetInnerHTML: {
                                __html: $$location.description
                              }
                            })
                      ]
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(LocationShowContentFeatures.make, {
                            features: $$location.features
                          }),
                      className: LocationShowContentDetailsCss.contentFeatures,
                      id: "contentFeatures"
                    })
              ],
              className: LocationShowContentDetailsCss.contentDetails,
              id: "contentDetails"
            });
}

var Css;

var make = LocationShowContentDetails;

export {
  Css ,
  make ,
}
/* H2 Not a pure module */
