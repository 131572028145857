// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var contentDescription = Cx.cx([
      "flex",
      "flex-col",
      "gap-1"
    ]);

var contentFeatures = Cx.cx([
      "flex",
      "flex-row",
      "flex-wrap",
      "justify-between",
      "gap-5"
    ]);

var contentDetails = Cx.cx([
      "flex",
      "flex-col",
      "gap-8"
    ]);

var contentDetailsTitle = Cx.cx([
      "text-base",
      "font-semibold",
      "text-gray-700",
      "uppercase"
    ]);

export {
  contentDescription ,
  contentFeatures ,
  contentDetails ,
  contentDetailsTitle ,
}
/* contentDescription Not a pure module */
